import "./NotFound.scss";
import { Layout } from "../components/";
import React from "react";

function NotFound () {
return <Layout>
  <body>
    <h1>404 Error</h1>
    <p>Address Not Found</p>
  </body>
</Layout>;
}

export default NotFound;
